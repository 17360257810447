{
  "name": "scan-ninja-dashboard",
  "version": "0.0.39",
  "scripts": {
    "ng": "ng",
    "start": "ng serve  --configuration local",
    "codespaces": "ng serve --host=0.0.0.0 --disable-host-check",
    "build": "ng build",
    "build:dev": "ng build --configuration development",
    "build:prod": "ng build --configuration production",
    "watch": "ng build --watch --configuration local",
    "deploy:hosting": "ng build --configuration production && firebase deploy --only hosting",
    "test": "ng test",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.4",
    "@angular/common": "^18.2.4",
    "@angular/compiler": "^18.2.4",
    "@angular/core": "^18.2.4",
    "@angular/fire": "~18.0.1",
    "@angular/forms": "^18.2.4",
    "@angular/platform-browser": "^18.2.4",
    "@angular/platform-browser-dynamic": "^18.2.4",
    "@angular/router": "^18.2.4",
    "@microsoft/applicationinsights-web": "^3.3.0",
    "@ng-bootstrap/ng-bootstrap": "^17.0.1",
    "@popperjs/core": "^2.11.8",
    "@stripe/stripe-js": "^4.5.0",
    "bootstrap": "^5.3.3",
    "bootstrap-icons": "^1.11.3",
    "chart.js": "^4.3.0",
    "firebase": "^11.0.1",
    "jquery": "^3.7.1",
    "jquery-ui": "^1.13.3",
    "ng2-charts": "^5.0.0",
    "ngx-highlight-js": "^18.0.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.6"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.4",
    "@angular-eslint/builder": "^18.3.0",
    "@angular-eslint/eslint-plugin": "^18.3.0",
    "@angular-eslint/eslint-plugin-template": "^18.3.0",
    "@angular-eslint/schematics": "^18.3.0",
    "@angular-eslint/template-parser": "^18.3.0",
    "@angular/cli": "~18.2.4",
    "@angular/compiler-cli": "^18.2.4",
    "@angular/localize": "^18.2.4",
    "@types/jasmine": "~5.1.4",
    "@typescript-eslint/eslint-plugin": "^8.6.0",
    "@typescript-eslint/parser": "^8.6.0",
    "eslint": "^9.10.0",
    "eslint-plugin-utils": "^0.3.2",
    "firebase-tools": "^13.17.0",
    "jasmine-core": "~5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ts-evaluator": "^1.2.0",
    "typescript": "~5.4.5",
    "webpack-dev-middleware": "^7.2.1"
  },
  "engines": {
    "node": ">=18.20.3 || >=20.13.1"
  }
}
